import Footer from "./Footer"
import Header from "./Header"
import * as React from "react"
import { navigate } from "gatsby"
import { isBrowser, isLoggedIn } from "../../utils/auth"

function Layout({ children }) {
  return (
    <>
      {!isLoggedIn() && !isBrowser() ? (
        navigate("/login")
      ) : (
        <>
          <Header />
          {children}
          <Footer />
        </>
      )}
    </>
  )
}

export default Layout
