export const isBrowser = () =>
  typeof window === "undefined" || typeof document === "undefined"
export const getUser = () => {
  if (!isBrowser()) {
    const cookies = document.cookie.split(';').reduce(
      (cookies, cookie) => {
          const [name, val] = cookie.split('=').map(c => c.trim());
          cookies[name] = val;
          return cookies;
      }, {});
    return cookies.ageConfirm === "true"
  } else {
    return false
  }
}

const setValue = user => {
  var date = new Date()
  var midnight = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59
  )
  let expired = "; expires=" + midnight.toGMTString()
  if (!isBrowser()) {
    document.cookie = `ageConfirm=${user};expires=${expired}`
  }
}
export const handleLogin = () => {
  setValue(true)
}
export const isLoggedIn = () => {
  const user = getUser()
  return user
}
